<template>
  <div class="sidebar-wrapper" :class="{ full: full }">
    <div class="logo">
      <img :src="LogoImage" alt="Logo" style="padding-bottom: 10px" />
    </div>

    <div class="top-items" v-if="fullSidebarItems.top.length">
      <SidebarItem
        v-for="(item, index) in fullSidebarItems.top"
        :key="'top-' + index"
        :href="item.href"
        :to="item.to"
        :label="item.label"
        @click="item.callback"
        :full="full"
        :image="item.image"
        :style="item.style"
      />
    </div>

    <div class="bottom-items" v-if="fullSidebarItems.bottom.length">
      <SidebarItem
        v-for="(item, index) in fullSidebarItems.bottom"
        :key="'bottom-' + index"
        :href="item.href"
        :to="item.to"
        :label="item.label"
        @click="item.callback"
        :full="full"
        :image="item.image"
        :style="item.style"
      />
      <slot name="bottom" :full="full"></slot>
    </div>
  </div>
</template>

<script>
import SidebarItem from "@/shared/components/SidebarItem.vue";
import LogoImage from "@/assets/icons/logo.png";

export default {
  name: "MainSidebar",
  components: { SidebarItem },
  props: ["sidebarItems"],
  data() {
    return {
      full: true,
      LogoImage: LogoImage,
    };
  },
  computed: {
    fullSidebarItems() {
      if (this.sidebarItems) {
        const items = JSON.parse(JSON.stringify(this.sidebarItems));
        if (!items.bottom) {
          items.bottom = [];
        }
        return items;
      }
      return { top: [], bottom: [] };
    },
  },
  methods: {
    triggerFull() {
      this.full = !this.full;
    },
  },
};
</script>

<style scoped lang="scss">
.sidebar-wrapper {
  grid-area: sidebar;
  width: var(--sidebar-width);
  height: 100vh;
  background-color: #f5f7f9;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  transition: width 0.3s ease;

  &.full {
    width: var(--sidebar-width);
  }

  .top-items {
    flex-grow: 1;
  }

  .bottom-items {
    margin-top: auto;
  }
}
</style>
